<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование записи поурочного планирования"
        update
        url-update="/lesson-plan/update/:id"
        url-back="/lesson-plan"
        action-one="lesson_plan/one"
        :fields="fields"
    >
        <lesson-plan-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import LessonPlanForm from '@/components/forms/LessonPlanForm.vue'
export default {
    name: 'LessonPlanFormUpdate',
    components: { FormView, LessonPlanForm },
    computed: {
        fields () {
            return [ 'year', 'subject', 'grade', 'number', 'theme', 'themes', 'content' ]
        }
    }
}
</script>